:root {
  --primary: #d81010 !important;
  --secondary: #3e4954;
  --primary-hover: #d32a2adf !important;
  --light-color: #fad5d5;
}

.bgl-primary {
  background-color: var(--light-color) !important;
}
[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li.mm-active
  > a {
  background-color: var(--light-color);
}
[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li
  > a:hover {
  color: var(--primary) !important;
}
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background-color: #d81010 !important;
}

.display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.gap {
  gap: 10px;
}
.hover-item-transition {
  transition: all 0.3s;
}
.hover-item-transition:hover {
  transform: scale(1.1);
}

.btn-primary {
  background-color: #d81010 !important;
  border: none !important;
}
.btn-primary:hover {
  background-color: #902828;
}
.login-form-bx .box-skew1:after {
  background-color: black !important;
  background-image: none !important;
}
.login-form-bx .inner-content {
  padding-left: 8rem !important;
}
.popup-warning-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid orange;
  color: orange;
  width: 60px;
  height: 60px;
  line-height: 26px;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
.table-scale {
  transition: all 0.3s;
}
.table-scale:hover {
  cursor: pointer;
  transform: scale(1.02);
}
.switch-disable {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.range-picker-input {
  border-radius: 0.75rem !important;
}
.picker .picker-input__text {
  border-radius: 0.75rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: none !important;
  padding: 1rem 1.25rem !important;
}
.picker__container {
  left: unset !important;
  right: 15px !important;
}
.filter-tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fad5d5;
  padding: 5px;
  border-radius: 8px;
  flex-wrap: nowrap;
}
.tab {
  margin: 0;
  color: #d81010;
  font-weight: 500;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  width: 90px;
  text-align: center;
  cursor: pointer;
}
.active-tab {
  background-color: #fff;
  padding: 8px 12px;
  border-radius: 6px;
  font-weight: 500;
  color: #000;
  margin: 0;
  width: 90px;
  text-align: center;
  transition: color 0.3s ease-in-out, background-color 0.5s ease-in-out,
    border-color 0.5s ease-in-out;
  /* transform: translateX(0.1s); */
}
.disabled-row {
  background-color: #e9ecef !important; /* Light gray background */
  color: #7d6f6c !important; /* Gray text */
  pointer-events: none !important; /* Disable clicking */
  opacity: 0.65 !important; /* Slightly fade the row */
}

.hover-table {
  transition: all 0.3s;
  color: #9b4444;
}
.hover-table:hover {
  transform: scale(1.2);
  cursor: pointer;
}
