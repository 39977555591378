.booking-page {
  display: flex;
  gap: 20px;
  padding: 20px;
  font-family: Arial, sans-serif;
  /* background-color: #f9f9f9; */
}

.left-panel {
  flex: 1;
}

.right-panel {
  flex: 2;
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

/* PropertyCard Styles */
.property-card {
  padding: 1rem;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.property-image {
  width: 100%;
  height: auto;
}

.property-info {
  padding: 15px;
}

.price-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.guarantee-text {
  font-size: 14px;
  color: #888;
  margin-top: 10px;
}

.agency-info h3 {
  margin: 10px 0 5px;
}

.contact-info {
  color: #555;
}

/* BookingDetails Styles */
.booking-details {
  margin-top: 20px;
}

.confirmation-title {
  font-size: 24px;
  color: #4caf50;
}

.appointment-date {
  font-size: 18px;
  color: #333;
}
.details p {
  margin: 0;
}

.details-section {
  margin-top: 20px;
  background: #fad5d5;
  border: 1px solid var(--primary);
  padding: 15px;
  border-radius: 8px;
}
.booking-profile-img {
  height: 150px;
  width: 150px;
}

.contact-person {
  margin-top: 20px;
}

.btn-write,
.btn-call {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  color: white;
  margin-right: 10px;
  cursor: pointer;
}

.btn-write {
  background-color: #2196f3;
}

.btn-call {
  background-color: #4caf50;
}

/* TenantInfo Styles */
.tenant-info {
  margin-top: 20px;
}

.tenant-list {
  display: flex;
  gap: 10px;
}

.tenant-card {
  background: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  flex: 1;
}
